import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const EdTechSolutionsNew = ({ solutions }) => {
    const [expandedItem, setExpandedItem] = useState(0)

    return (
        <div className="grid lg:grid-cols-2 md:gap-y-6 lg:gap-x-10 xl:gap-x-20 xl:grid-cols-[1fr_592px] mt-[3.125rem] items-baseline">
            <div className="">
                {solutions.map(({ title, description, acc_img }, idx) => {
                    const image = getImage(acc_img)
                    return (
                        <div
                            key={`solution-${idx}`}
                            className={`${expandedItem === idx ? '!bg-[#E1FBDA99] border border-[#9BD28F]' : ''} bg-[#FFFFFF] px-5 py-6 lg:px-[1.875rem] lg:py-[1.563rem] border-[0.063rem] border-solid border-[#9BD28F7D] mb-[0.625rem] rounded-[1.25rem] cursor-pointer hover:bg-[#E1FBDA99] transition-all duration-500`}
                            onClick={() => setExpandedItem(idx)}
                        >
                            <h3 className="text-black text-xl leading-[1.875rem] font-gordita font-medium">{title}</h3>
                            {expandedItem === idx && <div className="mt-[0.625rem]">
                                <p className="text-base">{description}</p>
                                <GatsbyImage image={image!} alt={title} className="block lg:hidden mt-4 max-lg:max-w-lg w-full mx-auto" />
                            </div>}
                        </div>
                    )
                })}
            </div>
            <figure className="max-lg:max-w-lg max-lg:mx-auto rounded-[0.875rem] overflow-hidden max-lg:hidden">
                {solutions.map(({ acc_img, title }, idx) => {
                    const image = getImage(acc_img)
                    return expandedItem === idx && <GatsbyImage key={`image-${idx}`} image={image!} alt={title} />
                })}
            </figure>
        </div>
    )
}

export default EdTechSolutionsNew