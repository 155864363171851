import React, { useEffect, useState } from "react"
import { Link, graphql, HeadFC } from "gatsby"
import useSiteMetadata from "../hooks/useSiteMetadata"
import SEO from "../components/SEO"

import ArrowWhite from "../assets/img/arrow-right.webp"

import AccMinus from "../assets/img/acc-minus.inline.svg"
import AccPlus from "../assets/img/acc-plus.inline.svg"
import IconOne from "../assets/img/team-icon-2.inline.svg"
import HireDevelopers from "../assets/img/hireDevelopers.inline.svg"
import InterviewIcon from "../assets/img/interview.inline.svg"
import NoteIcon from "../assets/img/note.inline.svg"

import ArrowUpblack from "../assets/img/arrow-up-black.webp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useContextValues } from "../context/NavBarContext"
import ProjectPortfolio from "../components/DashboardPortfolio/PortfolioList"
import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import TechnologyAccordian from "../components/TechnologyServiceAccordian"
import EdTechSolutions from "../components/EdTechSolutionsNew"
import Contact from "../components/Contact"

const Edtech = ({
    data: {
        edtechJson: post,
    },
}) => {
    const [showingBusinessBenefit, setShowingBusinessBenefit] = useState<Number>()

    const { isMobile, changeCurrentPath, changeNavColor } = useContextValues()

    useEffect(() => {
        changeCurrentPath(location.pathname)
        changeNavColor("white")
        return (() => changeNavColor("black"))
    }, [])

    return (
        <>
            <section className="banner-gradient pt-[6.25rem] md:pt-[9.6875rem] md:pb-[6.25rem] pb-[3.125rem] lg:pb-[8.5rem] bg-[#181818] relative before:content-[''] before:w-[56.5625rem] before:h-[56.5625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-0 z-[1] before:-z-50 before:opacity-[0.3] before:blur-[5.625rem] md:min-h-[729px] overflow-hidden">
                <div className="container">
                    <div className="banner-grid flex flex-wrap justify-center lg:grid md:grid-cols-[450px_1fr] xl:grid-cols-[1fr_1fr] grid-cols-[1fr] gap-[2.5rem] lg:gap-[3.125rem] max-lg:grid-cols-[1fr_386px]">
                        <div className="grid-left order-1 lg:order-[-1]">
                            <p className="small-title uppercase text-sm text-white mb-[1.875rem]">{post.paragraph_1}</p>
                            <h1 className="text-white font-blancomedium text-[2rem] lg:text-[2.9375rem] xl:text-[3.25rem] leading-[1.15] mb-[1.375rem]">
                                {post.title_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h1>
                            <p className="text-white text-lg leading-[1.66] font-gordita mb-5 md:mb-0">{post.description_1}</p>
                            <div className="btn-row md:mt-[1.875rem] lg:mt-[3.125rem]">
                                <Link to="/contact-us/" className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] max-sm:px-[1.25rem] hover:bg-transparent  uppercase">Get a Free 30 MinuteS Consultation
                                    <img
                                        src={ArrowWhite}
                                        alt="RightArrow"
                                        className="w-[7px] h-[14px]"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="grid-right lg:hidden max-lg:h-[28.125rem]">
                            <GatsbyImage image={getImage(post.heroImage)!} alt={post.alt} className="w-full h-full z-10 object-cover max-md:rounded-[1.25rem]" />
                        </div>
                    </div>
                </div>
                <p className="max-lg:hidden absolute md:w-[26.875rem] md:h-[21.25rem] lg:w-[33.75rem] lg:h-[26.25rem] xl:w-[38.625rem] h-auto xl:h-[30rem] top-[50%] right-0  translate-y-[-50%] before:content-[''] before:w-[26.375rem] before:h-[26.375rem] before:bg-[url('../assets/img/dots-bg.svg')] before:bg-center before:bg-no-repeat before:bg-cover before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[-90px] before:z-[-1]">
                    <GatsbyImage image={getImage(post.heroImage)!} alt={post.alt} className="w-full h-full  max-md:!hidden z-10" />
                </p>
            </section>

            <section className="py-10 md:py-12 xl:py-[110px]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-[20px] font-blancomedium text-center">
                            {post.title_2.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <p className="text-lg">
                            {post.description_2.map((chunk, idx) => chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1])}
                        </p>
                    </div>
                    <EdTechSolutions solutions={post.service_accordian} />
                </div>
            </section>

            <section className="lg:pb-[6.875rem] overflow-hidden md:pb-[3.125rem] pb-[1.875rem]">
                <div className="container">
                    <div className="grid-div bg-[#343434] max-sm:px-[20px] py-[1.4375rem] pl-[3.75rem] pr-[1.875rem] rounded-[1.875rem] grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[auto_270px] gap-[1.5625rem] md:gap-[3.125rem] lg:gap-[5rem] green-bg-gradient relative before:content-[''] before:w-[37.0625rem] before:h-[37.0625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-[-6.875rem] z-[1] before:-z[-1] before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden">
                        <div className="grid-content">
                            <p className="font-gorditamedium text-sm leading-[1.5] text-white mb-[0.75rem]">CASE STUDY</p>
                            <p className="text-[1.375rem] text-white leading-[1.45] font-gorditaregular font-normal">
                                {post.case_study.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </p>
                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                                <Link to={post.case_study.button[0]} className="ltext-lg relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 ">{post.case_study.button[1]}
                                </Link>
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear"
                                />
                            </div>
                        </div>
                        <div className="grid-img relative z-10">
                            <GatsbyImage image={getImage(post.case_study.case_study_img)!} alt={post.case_study.case_study_img_alt} className="rounded-[0.625rem] block mx-auto md:inline-block" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-[#F8F7F3] lg:py-[6.875rem] md:py-[3.125rem] py-[1.875rem]">
                <div className="container max-w-[67.8125rem] max-[991px]:max-w-[100%]">
                    <div className="benefit bg-[#fff] rounded-[1.25rem] border border-[#D3D8E0]">
                        <h2 className="text-black text-[2rem] leading-[2.1875rem] text-center lg:leading-[3.375rem] font-blancomedium py-[1.875rem] md:px-[1.875rem] lg:px-[3.3125rem] border-b border-[#D3D8E0] max-sm:px-5">
                            {post.benefits.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <div className="grid pt-[1.875rem] pb-[3.125rem] lg:px-[3.125rem] px-[25px] lg:gap-[5.625rem] gap-[3.125rem] grid-cols-2 max-sm:grid-cols-[1fr]">
                            <div className="grid-left">
                                <div className="text-center">
                                    <span className="font-gorditaregular inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.375rem] pb-[0.375rem] px-[1.375rem] rounded-[6.25rem] text-sm border border-black mb-[1.875rem]">{post.benefits.left_title}</span>
                                </div>
                                <ul className="text-left service-tabs">
                                    {post.benefits.left_benefits.map((benefit, idx) =>
                                        <li key={`left_benefit ${idx}`} className="text-black text-base leading-relaxed relative before:content-[''] before:w-[1.125rem] before:h-[1.125rem] before:absolute before:left-0 before:top-[0.125rem] before:bg-[url('../assets/img/tick-circle.webp')] before:bg-center before:bg-no-repeat block pl-[2.375rem] pb-5 max-sm:pb-[0.625rem] min-h-[4.25rem]">
                                            {benefit}
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="grid-right">
                                <div className="text-center">
                                    <span className="font-gorditaregular inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.375rem] pb-[0.375rem] px-[1.375rem] rounded-[6.25rem] text-sm border border-black mb-[1.875rem]">Business benefits</span>
                                </div>
                                <div>
                                    <ul>
                                        {post.benefits.right_benefits.map((benefit, idx) => {
                                            let totalCharacters = 0
                                            return (
                                                <li key={`right_benefit ${idx}`} className={`md:max-w-[24rem] relative pr-[1.875rem] ${idx === 0 ? '' : 'pt-5'} ${showingBusinessBenefit === idx ? '' : 'line-clamp-2'}`}>
                                                    {benefit.map((chunk, idx) => {
                                                        totalCharacters += chunk[1].length
                                                        return (
                                                            chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1]
                                                        )
                                                    })}
                                                    {totalCharacters > 87 && <span className={`expand-content cursor-pointer absolute w-[1.375rem] right-0 ${idx === 0 ? 'top-1' : 'top-[1.6875rem]'}`}>
                                                        {showingBusinessBenefit === idx ? <AccMinus onClick={() => setShowingBusinessBenefit(undefined)} className="w-[1.375rem] h-[1.375rem]" /> : <AccPlus onClick={() => setShowingBusinessBenefit(idx)} className="w-[1.375rem] h-[1.375rem]" />}
                                                    </span>}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lg:py-[6.875rem] md:py-[3.125rem] py-[1.875rem] bg-[#F5F5F5]">
                <div className="container md:max-w-[50.9375rem] max-sm:px-[1.25rem] px-5">
                    <div className="title text-center">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-8 font-blancomedium text-center">
                            {post.title_3.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                    </div>
                    <div className="mt-[2.1875rem]">
                        <TechnologyAccordian data={post.integration_accordian} isEdtech={true} />
                    </div>
                </div>
            </section>

            <section id="our-work" className="pt-[40px] lg:pb-[50px] md:pb-11 pb-0">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-8 font-blancomedium">Projects<span className="text-[#4BD965] italic"> powered by</span> our development team.</h2>
                    </div>
                    <ProjectPortfolio showBudget={false} showProjectTechnologies />
                </div>
            </section>

            <section className="hiring-steps bg-[#F8F7F3] py-10 md:py-12 xl:py-[6.875rem] overflow-hidden">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">{post.development_process.title}</h2>
                        <p className="text-lg">{post.development_process.description}</p>
                    </div>
                    <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">

                                <NoteIcon className="w-[1.5625rem] filter invert" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">1. Discovery</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Techuz works with you to understand your specific educational technology project goals and needs.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                <InterviewIcon className="w-[1.5625rem] filter invert" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">2. Design</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We create interactive prototypes and functional blueprints to visualize the solution and gather feedback early in the process.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">

                                <HireDevelopers className="w-[1.5625rem] filter invert" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">3. Development</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Our developers build the EdTech platform based on the agreed-upon design and test the platform to ensure it meets quality standards</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0 bg-opacity-100 lg:max-w-[15.75rem] max-lg:bg-[#F8F7F3]">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                <IconOne className="w-[1.5625rem] filter invert" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">4. Feedback + Iteration</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We address your feedback and incorporate potential adjustments to refine the final product as per requirements.</p>
                        </li>
                    </ul>
                    <div className="btn-row text-center mt-[50px]">
                        <Link
                            to="/contact-us/"
                            className="inline-flex gap-[0.625rem] items-center bg-white-200 justify-start inline-white text-black border border-white shadow-1xl text-base group rounded-lg font-gorditamedium group ease-in duration-300 pl-5 pt-[12px] pb-[12px] pr-5 hover:bg-primary bg-white hover:border-primary"
                        >Share your project requirements
                            <span className="inline-flex justify-center items-center rounded-full overflow-hidden relative ">
                                <img
                                    src={ArrowUpblack}
                                    alt="ArrowRight"
                                    className="w-[0.75rem] h-[0.75rem] rotate-[90deg] object-contain"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            <section id="technical-stack" className="py-10 md:py-12 lg:pt-[110px] pb-[3.125rem] lg:pb-0">
                <div className="container">
                    <div className="title-sec text-center">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            {post.ecosystem.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                    </div>
                    <div className="stack-wrapper mt-[1.875rem]">
                        {post.ecosystem.groups.map((group, idx) =>
                            <div key={`point ${idx}`} className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex">
                                <p className="lg:pl-[1.688rem] bg-[#343434] lg:min-w-[230px] xl:w-[245px] md:min-w-[220px] md:max-w-[220px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-lg font-gorditamedium italic inline-block max-md:mb-4 text-white max-md:w-full md:flex md:flex-wrap md:items-center">{group.name}</p>

                                <p className="inline-flex flex-wrap items-center lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10 gap-y-[0.9375rem]">
                                    {group.items.map((img, idx) =>
                                        <span key={`img ${idx}`} className={`inline-flex flex-wrap items-center relative max-md:text-sm md:text-base ${idx === (group.items.length - 1) ? "" : "pr-3 mr-3 before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%]"}`}>
                                            {img.item_img && <span className="inline-block align-middle mr-2 w-5">
                                                <GatsbyImage image={getImage(img.item_img)!} alt={img.item_name} className="align-middle" />
                                            </span>}
                                            {img.item_name}
                                        </span>
                                    )}

                                </p>
                            </div>
                        )}
                    </div>
                    <div className="btn-row text-center mt-[50px]">
                        <Link to="/contact-us/" className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase relative pr-[2.3125rem]">Schedule A Developer's Interview
                            <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300 group-hover:invert-[1] group-hover:brightness-[1]"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            {!isMobile && <section className="py-8  lg:py-10 xl:py-20 testimonial-sec md:py-[1.875rem]">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] px-[1.625rem] rounded-[6.25rem] mb-6 md:mb-[0.625rem] leading-[1.125rem] md:leading-[21px]">Testimonials</span></div>
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            Here’s what <span className="italic text-black md:text-primary">our clients say</span> about us
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">They’ve praised and highly rated our work on various review platforms.</p>
                    </div>

                    <TestimonialSlider />

                </div>
            </section >}

            <Faq faqData={post.faqs} />
            <Contact />

        </>
    )
}

export const query = graphql`
query ($slug: String!) {
    edtechJson(slug: { eq: $slug }) {
        seo_title
        seo_description
        application_script
        slug
        heroImage {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
            name
            publicURL
            extension
        }
        alt
        paragraph_1
        title_1
        description_1
        title_2
        description_2
        service_accordian {
            title
            description
            acc_img {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
        case_study {
          title
          button
          case_study_img {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        benefits {
            title
            left_title
            left_benefits
            right_title
            right_benefits
        }
        title_3
        integration_accordian {
            title
            description
        }
        development_process {
            title
            description
        }
        portfolios_slug
        ecosystem {
            title
            groups {
                name
                items {
                    item_name
                    item_img {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        faqs {
            key
            answer
            question
        }
    }
  }
`
export default Edtech

export const Head: HeadFC = ({ location, data }) => {
    const { siteUrl } = useSiteMetadata()

    const { seo_title, seo_description, application_script } = data["edtechJson"]

    return (
        <SEO
            title={seo_title}
            canonical={`${siteUrl}${location.pathname}`}
            description={seo_description}
        >
            {!!application_script && <script type="application/ld+json">{application_script}</script>}
        </SEO>
    )
}
