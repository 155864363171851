import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PortfolioDetail, { ProjectsProps } from "./PortfolioDetail"

export interface propsType {
  showBudget?: boolean
  showProjectTechnologies?: boolean
}


const PortfolioList = ({ showBudget = true, showProjectTechnologies = false }: propsType) => {
  const {
    allProjectSlideJson: { projects },
  } = useStaticQuery(graphql`
    query {
      allProjectSlideJson {
        projects: nodes {
          slug
          title
          subTitle
          idx
          description
          clutchLink
          industry
          country
          budget
          backgroundStyles
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 70)
            }
            publicURL
          }
        }
      }
    }
  `)

  return projects.map((project: ProjectsProps) => (
    <PortfolioDetail key={`portfolio-${project.idx}`} {...project} showBudget={showBudget} showProjectTechnologies={showProjectTechnologies} />
  ))
}

export default PortfolioList
